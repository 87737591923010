<template>
    <modal ref="modalViewFiles" tamano="modal-xl" titulo="Archivos adjuntos">
        <section class="m-3 view-files">
            <div class="row justify-content-center f-15 shadow29 bg-f6 py-3 mb-5">
                <div class="col-6 f-12">
                    <p class="text-general f-500">
                        Plan de mantenimiento:
                        <span class="text-5d pl-3 f-300"> {{ activityInfo.nombre_mantenimiento }} </span>
                    </p>
                </div>
                <div class="col-6 f-12">
                    <p class="text-general f-500">
                        Actividadad:
                        <span class="text-5d pl-3 f-300"> {{ activityInfo.nombre_tarea }} </span>
                    </p>
                </div>
            </div>
            <div class="row text-general f-12 f-500 mb-3 justify-content-center">
                <div class="col-auto w-200px text-center">
                    Nombre de archivos
                </div>
                <div class="col-5 text-center">
                    Descripción
                </div>
                <div class="col-auto w-110px text-center">
                    Adjunto
                </div>
                <div class="col-auto w-110px text-center">
                    Acciones
                </div>
            </div>
            <div v-for="(data,idx) in adjuntos" :key="idx"  class="row f-12 justify-content-center">
                <div class="col-auto w-200px text-center my-auto">
                    <p class="tres-puntos">{{ data.nombre }}</p>
                </div>
                <div class="col-5 my-auto">
                    <p class="f-12 lh-15"> {{ data.descripcion }} </p>
                </div>
                <div class="col-auto w-110px text-center my-auto">
                    <i 
                    :id="`${data.tipo === 'pdf' ? 'df_gbp' : '' }`" 
                    :class="`${setFileType(data.tipo)} ${data.tipo === 'pdf' ? '_df_custom ' : '' } f-18 cr-pointer`"
                    @click="previewFile(data)"
                    />
                </div>
                <div class="col-auto w-110px text-center my-auto icon-option">
                    <el-tooltip content="Descargar" effect="light" placement="left" visible-arrow>
                        <a class="icon-arrow-down-bold f-25 cr-pointer" :href="data.archivo | helper-storage" download />
                    </el-tooltip>
                    <el-tooltip content="Eliminar" effect="light" placement="right" visible-arrow>
                        <i class="icon-close-circle f-25 cr-pointer" @click="deleteFile(data.id)" />
                    </el-tooltip>
                </div>
            </div>
        </section>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            nombreArchivo: '',
            descripcion: '',
            activityInfo: {
                id_actividad: '',
                nombre_mantenimiento: '',
                nombre_tarea:'',
            },
            adjuntos: [],
            id_material: null,
            id_mantenimiento: null,
            fileType: '',
            filteredPdf: []
        }
    },
    computed: {
        ...mapGetters({
            eliminado: 'almacen/mantenimiento/steps/eliminado'
        }),
    },
    methods: {
        ...mapActions({
            getActivitiesSteps: 'almacen/mantenimiento/steps/Action_get_activities_steps',
            Action_delete_file_activities_step: 'almacen/mantenimiento/steps/Action_delete_file_activities_step'
        }),
        toggle(actividad, adjuntos, idMaterial, idMantenimiento, tipo){
            this.fileType = tipo
            this.activityInfo = {
                id_actividad: actividad.id,
                nombre_mantenimiento: actividad.mantenimiento.nombre,
                nombre_tarea: actividad.nombre_tarea,
            }
            this.adjuntos = adjuntos
            
            if (tipo === 'pdf') {
                var newAdjuntos = adjuntos.filter((value, index, arr) => value.tipo === 'pdf');
                this.adjuntos = newAdjuntos
            }

            this.id_material = idMaterial
            this.id_mantenimiento = idMantenimiento
            this.$refs.modalViewFiles.toggle()
        },
        async listSteps(){
            await this.getActivitiesSteps({
                id_material: this.$route.params.id_material, 
                id_mantenimiento: this.$route.params.id,
                id_actividad: this.activityInfo.id_actividad
            })
        },
        save(){
            this.toggle()
        },
        setFileType(type){
            const types = {
                jpg: 'icon-image-box',
                pdf: 'icon-pdf-file',
                mp4: 'icon-video'
            }
            return types[type] || ''
        },
        previewFile(data){
            if (data.tipo === 'pdf') this.set_pdf(data.archivo)
        },
        async deleteFile(id){
            await this.Action_delete_file_activities_step({
                id_material: this.id_material, 
                id_mantenimiento: this.id_mantenimiento,
                id_actividad: this.activityInfo.id_actividad,
                id: id
            })
            if (this.eliminado) {
                this.adjuntos = this.adjuntos.filter(el => el.id != id)
            }
            this.listSteps()
        },
    }
}
</script>

<style lang="scss" scoped>
.view-files{
    .bg-f6{
        background-color: #F6F6F6 ;
    }
    .w-200px{
        max-width: 200px;
        min-width: 200px;
    }
    .w-110px{
        max-width: 110px;
        min-width: 110px;
    }

}
</style>
