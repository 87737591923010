var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalCreateActivity",attrs:{"titulo":"Crear actividad de mantenimiento","adicional":"Guardar"},on:{"adicional":_vm.add}},[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row m-3 f-15 justify-content-center create-activity"},[_c('div',{staticClass:"col-12 mb-4"},[_c('required',{attrs:{"text":"Campos obligatorios"}})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('ValidationProvider',{attrs:{"name":"nombre de la actividad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre de la actividad "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.activity.nombre_tarea),callback:function ($$v) {_vm.$set(_vm.activity, "nombre_tarea", $$v)},expression:"activity.nombre_tarea"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('ValidationProvider',{attrs:{"name":"opcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-radio',{attrs:{"label":_vm.TIPO_HORARIO_CALENDARIO},model:{value:(_vm.activity.horario_calendario),callback:function ($$v) {_vm.$set(_vm.activity, "horario_calendario", $$v)},expression:"activity.horario_calendario"}},[_vm._v("Calendario")]),_c('el-radio',{attrs:{"label":_vm.TIPO_HORARIO_HORARIO},model:{value:(_vm.activity.horario_calendario),callback:function ($$v) {_vm.$set(_vm.activity, "horario_calendario", $$v)},expression:"activity.horario_calendario"}},[_vm._v("Horario")]),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),(_vm.activity.horario_calendario == _vm.TIPO_HORARIO_CALENDARIO)?_c('div',{staticClass:"col-12 mb-3"},[_c('ValidationProvider',{attrs:{"name":"tipo de calendario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Tipo de calendario "),_c('required')],1),_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Seleccione","size":"small"},model:{value:(_vm.activity.id_tipo_calendario),callback:function ($$v) {_vm.$set(_vm.activity, "id_tipo_calendario", $$v)},expression:"activity.id_tipo_calendario"}},_vm._l((_vm.tipos_calendario),function(tipo){return _c('el-option',{key:tipo.id,attrs:{"label":tipo.nombre,"value":tipo.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,false,1352925862)})],1):_vm._e(),(_vm.activity.horario_calendario == _vm.TIPO_HORARIO_HORARIO)?_c('div',{staticClass:"col-12 mb-3"},[_c('ValidationProvider',{attrs:{"name":"numero de horas","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Numero de horas "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.activity.unidades_tiempo),callback:function ($$v) {_vm.$set(_vm.activity, "unidades_tiempo", $$v)},expression:"activity.unidades_tiempo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}],null,false,1457697387)})],1):_vm._e(),_c('div',{staticClass:"col-12 mb-3"},[_c('ValidationProvider',{attrs:{"name":"descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Descripción")]),_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","autosize":{ minRows: 4 },"size":"small"},model:{value:(_vm.activity.descripcion),callback:function ($$v) {_vm.$set(_vm.activity, "descripcion", $$v)},expression:"activity.descripcion"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),(_vm.activity.id != null)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},_vm._l((_vm.activity.adjuntos.adjuntos),function(adj){return _c('div',{key:adj.id,staticClass:"col-6 mb-2"},[_c('div',{staticClass:"br-5 bg-f5 d-flex border"},[_c('p',{staticClass:"flex-fill tres-puntos f-11 pl-2 my-auto"},[_vm._v(_vm._s(adj.nombre))]),_c('div',{staticClass:"delete-file d-middle-center br-r-4 cr-pointer"},[_c('i',{staticClass:"icon-window-close text-white"})])])])}),0)]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }