<template>
    <section class="almacen-mantenimiento-ver-plan px-3">
        <div class="row my-3">
            <div class="col-auto d-flex my-auto f-14 f-600">
                <router-link :to="{ name: 'almacen.materiales.ver.mantenimiento', params: {id: $route.params.id_material} }"> Planes de mantenimiento </router-link>
                <p class="text-general pl-1"> / {{ mantenimiento }}</p>
            </div>
        </div>
        <titulo-divisor :titulo="mantenimiento" clase="text-general">
            <div class="row">
                <div class="icon-option my-auto">
                    <el-tooltip content="Agregar actividad" placement="top" effect="light" visible-arrow>
                        <i class="icon-plus-circle f-30" 
                            :class="permitAction('almacen.mantenimiento.agregar.actividad') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.mantenimiento.agregar.actividad', addActivity)"
                        />
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <!-- titulos -->
        <div class="row mx-0 f-14 text-general f-500 mb-3">
            <div class="col-2 text-center">
                Actividad
            </div>
            <div class="col-3 text-center">
                Cronograma
            </div>
            <div class="col-3 text-center">
                Descripción
            </div>
            <div class="col-2 text-center"
                :class="permitAction('almacen.mantenimiento.previsualizar.archivos.actividad') ? 'cr-pointer' : 'cr-not-allowed'" 
                @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.actividad')"
            >
                Adjuntos
            </div>
            <div class="col-2 text-center">
                Acciones
            </div>
        </div>
        <!-- listado -->
        <div id="accordion">
            <activities-steps 
                :info="actividades.data" 
                :idMaterial="idMaterial" 
                :idMantenimiento="idMantenimiento"
                @listActFiles="openModalviewListFiles"
                @listStepsFiles="openModalviewListFilesSteps"
                @reload="listActivities">
            </activities-steps>
        </div>
        
        <!-- partials  -->
        <modal-create-activity ref="openModalCreateActivity" />
        <modal-eliminar ref="openModalDeleteFile" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?" @delete="handleDeleteActivity" />
        <modal-eliminar ref="openModalDeleteStep" title="Eliminar paso de mantenimiento" mensaje="¿Está seguro que quiere eliminar paso de mantenimiento?"/>
        <modal-add-files ref="openModalAddFiles" @add-file="handleAddFile"/>
        <modal-add-step ref="openModalAddStep" @createStep="handleCreateStep" />
        <modal-list-files ref="openModalListFiles" />
        <modal-list-files-steps ref="openModalListFilesSteps" />
    </section>
</template>

<script>
//import { StepsFactory } from '~/domain/entities/almacen/mantenimiento/stepsActivities/factories/StepsFactory'
import {mapActions, mapGetters} from 'vuex'
import modalCreateActivity from './partials/modalCreateActivity.vue'
import modalAddFiles from './partials/modalAddFiles.vue'
import modalAddStep from './partials/modalAddStep.vue'
import modalListFiles from './partials/modalListFiles.vue'
import modalListFilesSteps from './partials/modalListFilesSteps.vue'
import activitiesSteps from './components/activitiesSteps'
export default {
    components:{
        modalCreateActivity,
        modalAddFiles,
        modalAddStep,
        modalListFiles,
        modalListFilesSteps,
        activitiesSteps,
    },
    data() {
        return {
            TIPO_HORARIO_HORARIO: 1, 
            TIPO_HORARIO_CALENDARIO: 0, 
            activityToDelete: {},
            activityToAttach: {},
            showData: false,
            idMaterial: this.$route.params.id_material, 
            idMantenimiento: this.$route.params.id,
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            actividades: 'almacen/mantenimiento/actividades/actividades',
            mantenimientos: 'almacen/mantenimiento/mantenimientos',
            steps: 'almacen/mantenimiento/steps/steps',
        }),
        mantenimiento(){
            return this.mantenimientos?.find(mtto => mtto.id == this.idMantenimiento)?.nombre
        }
    },
    async mounted(){
        this.listActivities()
    },
    methods: {
        ...mapActions({
            obtenerActividades: 'almacen/mantenimiento/actividades/Action_get_actividad',
            obtenerPlanesMantenimiento: 'almacen/mantenimiento/Action_get_plan_mantenimientos',
            crearArchivoAdjunto: 'almacen/mantenimiento/actividades/Action_create_adjunto',
            eliminarActividad: 'almacen/mantenimiento/actividades/Action_delete_actividad',
            toggleActividad: 'almacen/mantenimiento/actividades/Action_update_show_actividad',
            getActivitiesSteps: 'almacen/mantenimiento/steps/Action_get_activities_steps',
            createActivityStep: 'almacen/mantenimiento/steps/Action_create_activities_step',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
        }),
        async listActivities(){
            await this.obtenerActividades({
                id_material: this.$route.params.id_material, 
                id_mantenimiento: this.$route.params.id
            })
            this.getBreadcumbs(['almacen.main', 'almacen.mantenimiento'])
            if(this.mantenimientos.length == 0) await this.obtenerPlanesMantenimiento({id_material: this.idMaterial})
            this.spliceBreadcumbs([
                {
                    position: 2,
                    breadcumb: {
                        name: this.mantenimiento,
                        route: "almacen.mantenimiento.planes.ver",
                        params: { id: this.idMantenimiento }
                    }
                }
            ])
        },
        addActivity(){
            this.$refs.openModalCreateActivity.toggle()
        },
        editActivity(data){
            this.$refs.openModalCreateActivity.toggle(data)
        },
        openModalDeleteFile(actividad){
            this.activityToDelete = actividad.id
            this.$refs.openModalDeleteFile.toggle()
        },
        openModalAddFiles(actividad){
            this.activityToAttach = actividad
            this.$refs.openModalAddFiles.toggle()
        },
        async openPlan(index, idActividad){
            await this.getActivitiesSteps({
                id_material: this.$route.params.id_material, 
                id_mantenimiento: this.$route.params.id,
                id_actividad: idActividad
            })
            this.toggleActividad(index)
        },
        addStep(id_actividad){
            this.$refs.openModalAddStep.toggle(id_actividad)
        },
        openModalDeleteStep(){
        },
        async handleDeleteActivity(){
            await this.eliminarActividad({
                id_actividad: this.activityToDelete,
                id_material: this.$route.params.id_material,
                id_mantenimiento: this.$route.params.id,
            })
            .then(() => this.$notify({title: 'Eliminado correctamente', type: 'success'}))
            .catch(() => this.$notify({title: 'Ha ocurrido un error', type: 'error'}))
        },
        viewListFiles(){
            this.$refs.openModalListFiles.toggle()
        },
        openModalviewListFilesSteps(actividad, adjuntos, tipo){
            this.$refs.openModalListFilesSteps.toggle(actividad, adjuntos, this.idMaterial, this.idMantenimiento, tipo)
        },
        openModalviewListFiles(actividad, adjuntos, tipo){
            this.$refs.openModalListFiles.toggle(actividad, adjuntos, this.idMaterial, this.idMantenimiento, tipo)
        },
        async handleAddFile(attachment){
            attachment.actividad = undefined
            await this.crearArchivoAdjunto({
                adjunto: attachment,
                id_actividad: this.activityToAttach.id_materiales_mantenimiento,
                id_material: this.$route.params.id_material,
                id_mantenimiento: this.$route.params.id,
            })
        },
        async handleCreateStep(step, idActivity){
            await this.createActivityStep({
                id_material: this.idMaterial, 
                id_mantenimiento: this.idMantenimiento,
                id_actividad: idActivity,
                payload: {
                    "titulo": step.nombreActividad,
                    "descripcion": step.descripcion,
                    "id_materiales_mantenimientos_actividad": idActivity,
                    
                }
            })
            
        }
    
    },
    

}
</script>

<style lang="scss" scoped>
.almacen-mantenimiento-ver-plan{
    background-color: #fff;
    a{
        text-decoration: none !important;
        color: var(--color-5d) !important;
    }
    .description{
        min-height: 45px;
        max-height: 45px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .adj-item{
        .cant-adj{
            position: absolute;
            background-color: #B53438;
            top: -11px;
            right: -9px;
        }
    }
    .list-plan{
        .plan{
            &:hover{
                background-color: #DDDDDD;
            }

        }
        .border-bottom-general{
            border-bottom: 1px solid var(--color-general)
        }
    }
}
.icon-option{
    i.icon-plus-circle {
        color: var(--text-general) !important;
        &:hover{
            color: var(--color-general) !important;
        }
    }
}
</style>