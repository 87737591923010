<template>
    <modal ref="modalAddStep" titulo="Crear paso de Actividad" adicional="Guardar" @adicional="add">
        <ValidationObserver ref="validator">
            <div class="row m-3 f-15 justify-content-center create-activity">
                <div class="col-12 mb-3">
                    <p class="input-label-top">Nombre del paso</p>
                    <ValidationProvider name="nombre" v-slot="{ errors }" rules="required">
                        <el-input v-model="step.nombreActividad" class="w-100" size="small" />
                        <VeeError :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 mb-3">
                    <p class="input-label-top">Descripción</p>
                    <ValidationProvider name="descripción" v-slot="{ errors }" rules="required">
                        <el-input v-model="step.descripcion" type="textarea" :autosize="{ minRows: 4 }" class="w-100" size="small" />
                        <VeeError :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { StepsFactory } from '~/domain/entities/almacen/mantenimiento/stepsActivities/factories/StepsFactory'
export default {
    data(){
        return {
            step: StepsFactory.instantiateEmpty(),
            adjuntos:[],
            activityStep: null
        }
    },
    methods: {
        toggle(id_actividad){
            
            this.activityStep = id_actividad
            this.$refs.validator.reset();
            this.$refs.modalAddStep.toggle()
        },
        async add(){
            const valid = await this.$refs.validator.validate();
            if (valid) {
                this.$emit('createStep', this.step, this.activityStep)
                this.step = StepsFactory.instantiateEmpty()
                this.$refs.modalAddStep.toggle()
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.create-activity{
    .delete-file{
        width: 23px;
        height: 24px;
        background-color: #707070;
    }
}
</style>
