<template>
    <section class="component-activities-steps">
        <div v-for="(actividad, index) in info" :key="actividad.id" class="list-plan mb-3" :id="`headingOn${index}`">
            <div data-toggle="collapse" :data-target="`#collapseOn${index}`" aria-expanded="false" aria-controls="collapseOn" 
                class="row mx-0 py-2 mb-2 f-12 plan shadow29 cr-pointer" @click="openPlan(index, actividad.id)">
                    <div class="col-2 my-auto">
                        <p class="tres-puntos">{{ actividad.nombre_tarea }}</p>
                    </div>
                    <div class="col-3 my-auto mx-auto">
                        <div class="d-flex mx-auto gap-2">
                            <i :class="`${actividad.horario_calendario ? 'icon-clock-time-eight-outline' : 'icon-calendar-clock' } f-15 my-auto mr-1`" />
                            <div>
                                <template v-if="actividad.horario_calendario === TIPO_HORARIO_HORARIO">
                                    <p> Horario - Cada {{ actividad.unidades_tiempo || '' }} horas</p>
                                </template>
                                <template v-if="actividad.horario_calendario === TIPO_HORARIO_CALENDARIO">
                                    <p> Calendario - Cada {{ actividad.calendario && actividad.calendario.horas || '' }} horas</p>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 my-auto">
                        <p class="description lh-15 text-center overflow-hidden"> {{ actividad.descripcion }} </p>
                    </div>
                    <div class="col-2 my-auto"
                        :class="permitAction('almacen.mantenimiento.previsualizar.archivos.actividad') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.actividad')"
                    >
                        <div class="d-middle-center text-gray">
                            <div class="adj-item position-relative mx-2" :class="permitAction('almacen.mantenimiento.previsualizar.archivos.actividad') ? 'cr-pointer' : 'cr-not-allowed'" >
                                <div v-if="actividad.adjuntos.pdf" class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ actividad.adjuntos.pdf }}</div>
                                <i v-if="actividad.adjuntos.pdf === 1" id="df_gbp" class="icon-pdf-file f-18 _df_custom" @click="previewPdfAct(actividad.adjuntos.adjuntos)"/>
                                <i v-else class="icon-pdf-file f-18"
                                    @click.stop="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.actividad', viewListFilesStepsAct, [actividad, actividad.adjuntos.adjuntos, 'pdf'])"
                                />
                            </div>
                            <div class="adj-item position-relative mx-2" :class="permitAction('almacen.mantenimiento.previsualizar.archivos.actividad') ? 'cr-pointer' : 'cr-not-allowed'">
                                <div v-if="actividad.adjuntos.video > 0 " class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ actividad.adjuntos.video }}</div>
                                <i v-if="actividad.adjuntos.video == 0" class="icon-video f-18" />
                                <i v-else class="icon-video f-18"
                                    @click.stop="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.actividad', previewVideosAct, [actividad.adjuntos.adjuntos,'actividades'])"
                                />
                            </div>
                            <div class="adj-item position-relative mx-2" :class="permitAction('almacen.mantenimiento.previsualizar.archivos.actividad') ? 'cr-pointer' : 'cr-not-allowed'">
                                <div v-if="actividad.adjuntos.imagen" class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ actividad.adjuntos.imagen }}</div>
                                <i v-if="actividad.adjuntos.imagen === 0" class="icon-image-box f-18"/>
                                <i v-else class="icon-image-box f-18"
                                    @click.stop="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.actividad', previewImgNew, [actividad.adjuntos.adjuntos] )"
                                />
                            </div>
                            <div class="adj-item position-relative mx-2" :class="permitAction('almacen.mantenimiento.previsualizar.archivos.actividad') ? 'cr-pointer' : 'cr-not-allowed'">
                                <div v-if="actividad.adjuntos.otros" class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ actividad.adjuntos.otros }}</div>
                                <i class="icon-folder-line f-18"
                                    @click.stop="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.actividad', viewListFilesStepsAct, [actividad, actividad.adjuntos.adjuntos, 'all'])"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-2 text-center my-auto icon-option">
                        <el-tooltip content="Agregar paso" effect="light" visible-arrow>
                            <i class="icon-plus-box f-22"
                                :class="permitAction('almacen.mantenimiento.agregar.paso.actividad') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click.stop="functionPermitAction('almacen.mantenimiento.agregar.paso.actividad', addStep, [actividad.id, index])"
                            />
						</el-tooltip>
						<el-tooltip content="Editar" effect="light" visible-arrow>
							<i class="icon-pencil f-22" 
                                :class="permitAction('almacen.mantenimiento.agregar.paso.actividad') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click.stop="functionPermitAction('almacen.mantenimiento.agregar.paso.actividad', editActivity, [actividad])"
                            />
						</el-tooltip>
                        <el-tooltip content="Eliminar" effect="light" visible-arrow>
                            <i class="icon-trash-can-outline f-22"
                                :class="permitAction('almacen.mantenimiento.agregar.paso.actividad') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click.stop="functionPermitAction('almacen.mantenimiento.agregar.paso.actividad', openModalDeleteFile, [actividad])"
                            />
                        </el-tooltip>
                        <el-tooltip content="Adjuntar" effect="light" visible-arrow>
                            <i class="icon-paperclip f-22" 
                                :class="permitAction('almacen.mantenimiento.agregar.paso.actividad') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click.stop="functionPermitAction('almacen.mantenimiento.agregar.paso.actividad', openModalAddFiles, [actividad])"
                            />
                        </el-tooltip>
                    </div>
            </div>
            <section :id="`collapseOn${index}`" class="collapse" :aria-labelledby="`headingOn${index}`" data-parent="#accordion">
                <div v-show="collapsed === index">
                    <!-- childrens -->
                    <div class="row mx-5 f-14 border-bottom-general f-500 mt-3 mb-2 position-relative">
                        <div class="col-1">
                            Posición
                        </div>
                        <div class="col-11">
                            <div class="row mx-0">
                                <div class="col-3 text-center">
                                    Pasos
                                </div>
                                <div class="col-5 text-center">
                                    Descripción
                                </div>
                                <div class="col-2 text-center"
                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                    @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.paso')"
                                >
                                    Adjuntos
                                </div>
                                <div class="col-2 text-center">
                                    Acciones
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- data childrens -->
                    <draggable tag="div" :list="steps" class="" handle=".handle" @change="updatePositionSteps($event)">
                        <div v-for="(child,idx) in steps" :key="idx" class="row mx-5 f-12 mb-3 py-2">
                            <div class="col-1 my-auto">
                                <div class="wh-26 bg-general rounded-circle d-middle-center text-white f-14 f-500 mx-auto">
                                    {{ child.posicion = idx+1 }}
                                </div>
                            </div>
                            <div class="col-11">
                                <div class="row border mx-0 br-4 py-2">
                                    <div class="col-3 my-auto d-flex gap-half align-items-center">
                                        <i class="icon-menu f-20 handle cr-grap"/>
                                        <p class="tres-puntos">{{ child.titulo }}</p>
                                    </div>
                                    <div class="col-5 my-auto">
                                        <p class="description lh-15 text-justify overflow-hidden align-items-center"> {{ child.descripcion }} </p>
                                    </div>
                                    <div class="col-2 my-auto">
                                        <div class="d-middle-center text-gray">
                                            <div class="adj-item position-relative mx-2">
                                                <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ child.adjuntos.pdf }}</div>
                                                <i v-if="child.adjuntos.pdf == 1" id="df_gbp" class="icon-pdf-file f-18 _df_custom"
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                    @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.paso', previewPdfAct, [child.adjuntos.archivos])"
                                                />
                                                <i v-else class="icon-pdf-file f-18"
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                    @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.paso', viewListFilesSteps, [actividad, child.adjuntos.archivos, 'pdf'])"
                                                />
                                            </div>
                                            <div class="adj-item position-relative mx-2">
                                                <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ child.adjuntos.video }}</div>
                                                <i v-if="child.adjuntos.video == 0" class="icon-video f-18" 
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                />
                                                <i v-else class="icon-video f-18"
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                    @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.paso', previewVideos, [child.adjuntos.archivos])"
                                                />
                                            </div>
                                            <div class="adj-item position-relative mx-2">
                                                <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">{{ child.adjuntos.imagen }}</div>
                                                <i v-if="child.adjuntos.imagen == 0" class="icon-image-box f-18" 
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                />
                                                <i v-else class="cr-pointer icon-image-box f-18"
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                    @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.paso', previewImg, [child.adjuntos.archivos])"
                                                />
                                            </div>
                                            <div class="adj-item position-relative mx-2">
                                                <i v-if="child.adjuntos.archivos.length == 0" class="icon-folder-line f-18" 
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                />
                                                <i v-else class="cr-pointer icon-folder-line f-18"
                                                    :class="permitAction('almacen.mantenimiento.previsualizar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                    @click="functionPermitAction('almacen.mantenimiento.previsualizar.archivos.paso', viewListFilesSteps, [actividad, child.adjuntos.archivos, 'all'])"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 my-auto text-center icon-option">
                                        <el-tooltip content="Editar" effect="light" visible-arrow>
                                            <i class="icon-pencil f-22"
                                                :class="permitAction('almacen.mantenimiento.editar.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                @click="functionPermitAction('almacen.mantenimiento.editar.paso', editStep, [child, actividad.id])"
                                            />
                                        </el-tooltip>
                                        <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                            <i class="icon-trash-can-outline f-22"
                                                :class="permitAction('almacen.mantenimiento.eliminar.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                @click="functionPermitAction('almacen.mantenimiento.eliminar.paso', openModalDeleteStep, [child.id])"
                                            />
                                        </el-tooltip>
                                        <i class="icon-paperclip f-22"
                                            :class="permitAction('almacen.mantenimiento.adjuntar.archivos.paso') ? 'cr-pointer' : 'cr-not-allowed'" 
                                            @click="functionPermitAction('almacen.mantenimiento.adjuntar.archivos.paso', openModalAddFilesSteps, [actividad, child.id])"
                                        />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </draggable>
                </div>
            </section>
        </div>
        
        <!-- partials  -->
        <modal-create-activity ref="openModalCreateActivity" />
        <modal-eliminar ref="openModalDeleteFile" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?" @delete="handleDeleteActivity" />
        <modal-eliminar ref="openModalDeleteStep" title="Eliminar paso de mantenimiento" mensaje="¿Está seguro que quiere eliminar paso de mantenimiento?" @delete="handleDeleteStep" />
        <modal-add-files ref="openModalAddFiles" @add-file="handleAddFile"/>
        <modal-add-files-steps ref="openModalAddFilesSteps" @add-file-step="handleAddFileStep"/>
        <modal-list-files ref="openModalListFiles" />
        <modal-add-step ref="openModalAddStep" @createStep="handleCreateStep" />
        <modal-edit-step ref="openModalEditStep" @edit-step="handleEditStep" />
        <modal-preview-img ref="openModalPreviewImgs" />
        <modal-list-videos ref="openModalPreviewVideos" />
        <modal-image-gallery ref="openModalPreviewImgs2" />
    </section>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import modalCreateActivity from '../partials/modalCreateActivity.vue'
import modalListFiles from '../partials/modalListFiles.vue'
import modalAddFiles from '../partials/modalAddFiles.vue'
import modalAddFilesSteps from '../partials/modalAddFilesSteps.vue'
import modalAddStep from '../partials/modalAddStep.vue'
import modalEditStep from '../partials/modalEditStep.vue'
import modalPreviewImg from '../partials/modalPreviewImg.vue'
import modalListVideos from '../partials/modalListVideos.vue'
export default {
    components: {
        modalCreateActivity,
        modalAddFiles,
        modalAddFilesSteps,
        modalAddStep,
        modalEditStep,
        modalListFiles,
        modalPreviewImg,
        modalListVideos,
    },
    props:{
        info:{
            type: Array,
            default: () => [],
            required: true
        },
        idMaterial:{
            type: [String,Number]
        },
        idMantenimiento:{
            type: [String,Number]
        },
    },
    data() {
        return {
            TIPO_HORARIO_HORARIO: 1, 
            TIPO_HORARIO_CALENDARIO: 0,
            collapsed: null,
            actividadId: null,
            idActivityToEdit: null,
            idActivityStepToDelete: null,
            activityToAttach: '',
            stepToUploadFile: '',
            srcList: [],
            planIndex: null
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            actividades: 'almacen/mantenimiento/actividades/actividades',
            steps: 'almacen/mantenimiento/steps/steps',
        }),
    },
    methods: {
         ...mapActions({
            obtenerActividades: 'almacen/mantenimiento/actividades/Action_get_actividad',
            crearArchivoAdjunto: 'almacen/mantenimiento/actividades/Action_create_adjunto',
            eliminarActividad: 'almacen/mantenimiento/actividades/Action_delete_actividad',
            toggleActividad: 'almacen/mantenimiento/actividades/Action_update_show_actividad',
            getActivitiesSteps: 'almacen/mantenimiento/steps/Action_get_activities_steps',
            createActivityStep: 'almacen/mantenimiento/steps/Action_create_activities_step',
            editActivityStep: 'almacen/mantenimiento/steps/Action_edit_activities_step',
            deleteActivityStep: 'almacen/mantenimiento/steps/Action_delete_activities_step',
            updatePositionActivityStep: 'almacen/mantenimiento/steps/Action_update_position_activities_step',
            uploadFileActivityStep: 'almacen/mantenimiento/steps/Action_upload_file_activities_step',
        }),
        addActivity(){
            this.$refs.openModalCreateActivity.toggle()
        },
        editActivity(data){
            this.$refs.openModalCreateActivity.toggle(data)
        },
        openModalDeleteFile(actividad){
            this.activityToDelete = actividad.id
            this.$refs.openModalDeleteFile.toggle()
        },
        viewListFiles(){
            this.$refs.openModalListFiles.toggle()
        },
        async openPlan(index, idActividad){
            this.collapsed = index
            this.actividadId  = idActividad 
            if(!this.info[index].show) this.listSteps()
            this.info[index].show = !this.info[index].show
        },
        async listSteps(){
            await this.getActivitiesSteps({
                id_material: this.$route.params.id_material, 
                id_mantenimiento: this.$route.params.id,
                id_actividad: this.actividadId
            })
        },
        addStep(id_actividad, index){
            this.planIndex = index
            this.$refs.openModalAddStep.toggle(id_actividad)
        },
        editStep(step, id){
            this.idActivityToEdit = id
            this.$refs.openModalEditStep.toggle(step,this.idMaterial, this.idMantenimiento)
        },
        openModalDeleteStep(id){
            this.idActivityStepToDelete = id
            this.$refs.openModalDeleteStep.toggle()
        },
        async handleDeleteActivity(){
            await this.eliminarActividad({
                id_actividad: this.activityToDelete,
                id_material: this.$route.params.id_material,
                id_mantenimiento: this.$route.params.id,
            })
            .then(() => this.$notify({title: 'Eliminado correctamente', type: 'success'}))
            .catch(() => this.$notify({title: 'Ha ocurrido un error', type: 'error'}))
        },
        openModalAddFiles(actividad){
            this.activityToAttach = actividad
            this.$refs.openModalAddFiles.toggle()
        },
        openModalAddFilesSteps(actividad, idStep){
            this.activityToAttach = actividad
            this.stepToUploadFile = idStep
            this.$refs.openModalAddFilesSteps.toggle()
        },
        editActivity(data){
            this.$refs.openModalCreateActivity.toggle(data)
        },
        async handleAddFile(attachment){
            attachment.actividad = undefined
            await this.crearArchivoAdjunto({
                adjunto: attachment,
                id_actividad: this.activityToAttach.id,
                id_material: this.idMaterial,
                id_mantenimiento: this.idMantenimiento,
            })
            this.$emit('reload')
        },
        async handleAddFileStep(attachment){

            await this.uploadFileActivityStep({
                adjunto: attachment,
                id_actividad: this.actividadId,
                id_material: this.idMaterial,
                id_mantenimiento: this.idMantenimiento,
                id_paso: this.stepToUploadFile,
                
            })

            this.listSteps()
        },
        // steps
        
        async handleEditStep(step){
            await this.editActivityStep({
                id_material: this.idMaterial, 
                id_mantenimiento: this.idMantenimiento,
                id_actividad: this.idActivityToEdit,
                id: step.id,
                payload: {
                    "titulo": step.titulo,
                    "descripcion": step.descripcion,
                    "id_materiales_mantenimientos_actividad": step.id_materiales_mantenimientos_actividad,
                }
            })
            this.listSteps()
        },
        async handleDeleteStep(){
            await this.deleteActivityStep({
                id_material: this.idMaterial, 
                id_mantenimiento: this.idMantenimiento,
                id_actividad: this.actividadId,
                id: this.idActivityStepToDelete
            })
            this.listSteps()
        },
        viewListFilesSteps(actividad, adjuntos, tipo){
            this.$emit('listStepsFiles', actividad, adjuntos, tipo)
        },
        async updatePositionSteps(){
            
            let data = this.steps.map(e => ({ id: e.id, posicion: e.posicion }))
            await this.updatePositionActivityStep({
                id_material: this.idMaterial, 
                id_mantenimiento: this.idMantenimiento,
                id_actividad: this.idActivityToEdit,
                payload: { data }
            })
            this.listSteps()
        },
        async handleCreateStep(step, idActivity){
            this.actividadId  = idActivity 
            await this.createActivityStep({
                id_material: this.idMaterial, 
                id_mantenimiento: this.idMantenimiento,
                id_actividad: this.actividadId,
                payload: {
                    "titulo": step.nombreActividad,
                    "descripcion": step.descripcion,
                    "id_materiales_mantenimientos_actividad": idActivity,
                }
            })
            if(this.info?.[this.planIndex]?.show) this.listSteps()
        },
        viewListFilesStepsAct(actividad, adjuntos, tipo){
            this.$emit('listActFiles', actividad, adjuntos, tipo)
        },
        previewImgNew(adjuntos){
            let onlyImg = []
            adjuntos.forEach(e => {
                let ext = e.archivo.split(".").pop();
                if (ext == 'png' || ext == 'jpg' || ext == 'jpeg') {
                    onlyImg.push(e.archivo)
                }
            });
            this.$refs.openModalPreviewImgs2.toggle(onlyImg,'Ver imagenes')
        },
        previewImg(adjuntos){
            this.$refs.openModalPreviewImgs.toggle(adjuntos)
        },
        previewVideos(adjuntos){
            this.$refs.openModalPreviewVideos.toggle(adjuntos)
        },
        previewVideosAct(adjuntos,actividades){
            this.$refs.openModalPreviewVideos.toggle(adjuntos,actividades)
        },
        previewPdfAct(adjuntos){
            adjuntos.forEach(e => {
                let ext = e.archivo.split(".").pop();
                if (ext == 'pdf') {
                    this.set_pdf(e.archivo)
                }
            });
        },
    }

}
</script>

<style lang="scss" scoped>
.component-activities-steps{
      background-color: #fff;
    a{
        text-decoration: none !important;
        color: var(--color-5d) !important;
    }
    .description{
        display: flex;
        min-height: 45px;
        max-height: 48px;
        text-align: justify !important;
        word-break: break-all;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .adj-item{
        .cant-adj{
            position: absolute;
            background-color: #B53438;
            top: -11px;
            right: -9px;
            z-index: 1;
        }
    }
    .list-plan{
        .plan{
            &:hover{
                background-color: #DDDDDD;
            }

        }
        .border-bottom-general{
            border-bottom: 1px solid var(--color-general)
        }
    }
}

.text-gray{
    color: #959595 !important;
}

.cr-grap{
    cursor: grab;
}
</style>