<template>
    <modal ref="modalAddFile" tamano="modal-md" titulo="Adjuntar archivos" adicional="Guardar" @adicional="save">
        <ValidationObserver ref="validator">
            <div class="ml-4">
                <required text="Campos obligatorios"/>
            </div>
            <div class="row justify-content-center m-3 f-15">
                <div class="col-auto">
                    <ValidationProvider rules="required|ext:jpg,png,pdf,mov,mp4" v-slot="{ errors }" name="archivo">
                        <drag-upload v-model="attachment.archivo" :required="true"/>
                        <vee-error class="text-center" :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Nombre del archivo <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <el-input v-model="attachment.nombre" class="f-12 br-5 w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Comentarios <required/></p>
                    <ValidationProvider v-slot="{ errors }" name="comentario" rules="required">
                        <el-input v-model="attachment.descripcion" type="textarea" :autosize="{ minRows: 3}" class="f-12 br-5 w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { AttachmentVOFactory } from '~/domain/value_objects/almacen/mantenimiento/actividades/factories/AttachmentVOFactory'

export default {
    data(){
        return {
            attachment: AttachmentVOFactory.instantiateEmpty(),
            nombreArchivo: '',
            descripcion: '',
            file_instance: {}
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAddFile.toggle()
        },
        async save(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return 

            this.$emit('add-file', this.attachment)
            this.attachment = AttachmentVOFactory.instantiateEmpty()
            this.$refs.validator.reset()
            this.toggle()
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
    }
}
</script>

<style lang="css" scoped>
</style>
