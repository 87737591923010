<template>
    <modal ref="modalViewFiles" tamano="modal-xl" titulo="Archivos adjuntos en las actividades">
        <section class="m-3 view-files">
            <div class="row justify-content-center f-15 shadow29 bg-f6 py-3 mb-5">
                <div class="col-6 f-12">
                    <p class="text-general f-500">
                        Plan de mantenimiento:
                        <span class="text-5d pl-3 f-300">Plan de mantenimiento 1</span>
                    </p>
                </div>
                <div class="col-6 f-12">
                    <p class="text-general f-500">
                        Actividadad:
                        <span class="text-5d pl-3 f-300">Revisión de aislamientos</span>
                    </p>
                </div>
            </div>
            <div class="row text-general f-12 f-500 mb-3 justify-content-center">
                <div class="col-auto w-200px text-center">
                    Nombre de archivos
                </div>
                <div class="col-5 text-center">
                    Descripción
                </div>
                <div class="col-auto w-110px text-center">
                    Adjunto
                </div>
                <div class="col-auto w-110px text-center">
                    Descargar
                </div>
            </div>
            <div v-for="(data,idx) in adjuntos" :key="idx" class="row f-12 justify-content-center">
                <div class="col-auto w-200px text-center my-auto">
                    <p class="tres-puntos">{{data.nombre}}</p>
                </div>
                <div class="col-5 my-auto">
                    <p class="f-12 lh-15"> {{ data.descripcion ? data.descripcion : 'Sin descripción' }} </p>
                </div>
                <div class="col-auto w-110px text-center my-auto">
                    <i 
                    :id="`${ext(data.archivo) === 'pdf' ? 'df_gbp': '' }`" 
                    :class="`${setType(data.archivo)} ${ ext(data.archivo) === 'pdf' ? '_df_custom' : '' } f-18 cr-pointer`"
                    @click="previewFile(data.archivo)"
                    />
                </div>
                <div class="col-auto w-110px text-center my-auto icon-option">
                    <el-tooltip content="Descargar" effect="light" visible-arrow>
                        <a class="icon-arrow-down-bold f-25 cr-pointer" :href="data.archivo | helper-storage" :download="data.nombre" />
                    </el-tooltip>
                </div>
            </div>
        </section>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            nombreArchivo: '',
            descripcion: '',
            fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],
            activityInfo: {
                id_actividad: '',
                nombre_mantenimiento: '',
                nombre_tarea:'',
            },
            adjuntos: [],
            id_material: null,
            id_mantenimiento: null,
            fileType: '',
            filteredPdf: []
        }
    },
    methods: {
        toggle(actividad, adjuntos, idMaterial, idMantenimiento, tipo){
            this.adjuntos = adjuntos
            if (tipo === 'pdf') {
                let onlyImg = []
                adjuntos.forEach(e => {
                    let ext = e.archivo.split(".").pop();
                    if (ext == 'pdf') {
                        onlyImg.push(e)
                    }
                });
                //var newAdjuntos = adjuntos.filter((value, index, arr) => value.tipo === 'pdf');
                this.adjuntos = onlyImg
            }

            this.$refs.modalViewFiles.toggle()
        },
        ext(file) {
            return file.split(".").pop()
        },
        setType(file){
            let ext = file.split(".").pop();
            const types = {
                jpg: 'icon-image-box',
                jpeg: 'icon-image-box',
                png: 'icon-image-box',
                pdf: 'icon-pdf-file',
                mp4: 'icon-video',
                mkv: 'icon-video',
            }
            return types[ext] || ''
        },
        previewFile(file){
            if (this.ext(file) === 'pdf') this.set_pdf(file)
        },
        save(){
            this.toggle()
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
    }
}
</script>

<style lang="scss" scoped>
.view-files{
    .bg-f6{
        background-color: #F6F6F6 ;
    }
    .w-200px{
        max-width: 200px;
        min-width: 200px;
    }
    .w-110px{
        max-width: 110px;
        min-width: 110px;
    }

}
</style>
