<template>
    <modal ref="modalPreviewImg" tamano="modal-xl" titulo="Ver Imagenes">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-2 my-auto text-right">
                <i class="icon-arrow-left-drop-circle cr-pointer f-25" @click="previousImg" />
            </div>
            <div class="col-8 text-center">
                <img 
                class="w-100 obj-cover"
                style="height: calc(100vh - 300px)"
                :src="image | helper-storage"
                >
            </div>
            <div class="col-2 my-auto text-left">
                <i class="icon-arrow-right-drop-circle cr-pointer f-25" @click="nextImg" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            filteredAdjuntos: [],
            selected: 0,
        }
    },
    computed: {
        image(){
            return this.filteredAdjuntos?.[this.selected]?.archivo
        } 
    },
    methods: {
        toggle(adjuntos){
            this.filteredAdjuntos = adjuntos.filter((value) => value.tipo === 'jpg');
            this.$refs.modalPreviewImg.toggle()
        },
        nextImg(){  
            if(this.filteredAdjuntos.length) this.selected = ((this.selected++) >= (this.filteredAdjuntos.length - 1)) ? 0: this.selected++
        },
        previousImg(){
            if(this.filteredAdjuntos.length) this.selected = ((this.selected--) <= 0) ? 0: this.selected--
        },
    }
}
</script>

<style lang="css" scoped>
</style>
