<template>
    <modal ref="modalCreateActivity" titulo="Crear actividad de mantenimiento" adicional="Guardar" @adicional="add">
        <ValidationObserver ref="validator">
            <div class="row m-3 f-15 justify-content-center create-activity">
                <div class="col-12 mb-4">
                    <required text="Campos obligatorios"/>
                </div>
                <div class="col-12 mb-3">
                    <ValidationProvider name="nombre de la actividad" v-slot="{ errors }" rules="required">
                        <p class="input-label-top">Nombre de la actividad <required/></p>
                        <el-input v-model="activity.nombre_tarea" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 mb-3">
                    <ValidationProvider name="opcion" v-slot="{ errors }" rules="required">
                        <el-radio v-model="activity.horario_calendario" :label="TIPO_HORARIO_CALENDARIO">Calendario</el-radio>
                        <el-radio v-model="activity.horario_calendario" :label="TIPO_HORARIO_HORARIO">Horario</el-radio>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div v-if="activity.horario_calendario == TIPO_HORARIO_CALENDARIO" class="col-12 mb-3">
                    <ValidationProvider name="tipo de calendario" v-slot="{ errors }" rules="required">
                        <p class="input-label-top">Tipo de calendario <required/></p>
                        <el-select v-model="activity.id_tipo_calendario" placeholder="Seleccione" size="small" class="w-100">
                            <el-option v-for="tipo in tipos_calendario" :key="tipo.id" :label="tipo.nombre" :value="tipo.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div v-if="activity.horario_calendario == TIPO_HORARIO_HORARIO" class="col-12 mb-3">
                    <ValidationProvider name="numero de horas" v-slot="{ errors }" rules="required|numeric">
                        <p class="input-label-top">Numero de horas <required/></p>
                        <el-input v-model="activity.unidades_tiempo" size="small" class="w-100" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 mb-3">
                    <ValidationProvider name="descripción" v-slot="{ errors }" rules="required">
                        <p class="input-label-top">Descripción</p>
                        <el-input v-model="activity.descripcion" type="textarea" :autosize="{ minRows: 4 }" class="w-100" size="small" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div v-if="activity.id != null" class="col-12">
                    <div class="row">
                        <div v-for="adj in activity.adjuntos.adjuntos" :key="adj.id" class="col-6 mb-2">
                            <div class="br-5 bg-f5 d-flex border">
                                <p class="flex-fill tres-puntos f-11 pl-2 my-auto">{{ adj.nombre }}</p>
                                <div class="delete-file d-middle-center br-r-4 cr-pointer">
                                    <i class="icon-window-close text-white" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ActivityFactory } from '~/domain/entities/almacen/mantenimiento/actividades/factories/ActivityFactory'

export default {
    data(){
        return {
            TIPO_HORARIO_HORARIO: 1, 
            TIPO_HORARIO_CALENDARIO: 0, 
            activity: ActivityFactory.instantiateEmpty(),
        }
    },
    computed :{
        ...mapGetters({
            tipos_calendario: 'almacen/mantenimiento/actividades/tipos_calendario',
        })
    },
    methods: {
        ...mapActions({
            obtenerTiposCalendarios: 'almacen/mantenimiento/actividades/Action_get_tipos_calendario',
            crearActividad: 'almacen/mantenimiento/actividades/Action_create_actividad',
            editarActividad: 'almacen/mantenimiento/actividades/Action_edit_actividad',
        }),

        toggle(data){
            this.cleanFields()
            if(data){
                const { show, ...rest} = data
                this.activity = ActivityFactory.instantiate(rest)
            }
            this.$refs.validator.reset()
            this.$refs.modalCreateActivity.toggle()
        },

        async add(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return;
            if (this.activity.id != null && this.activity.id != '') {
                 await this.editarActividad({
                    payload: this.activity,
                    id_material: this.$route.params.id_material,
                    id_mantenimiento: this.$route.params.id,
                    id_actividad: this.activity.id
                })
                
            } else {
                const { show, ...rest } = this.activity
                await this.crearActividad({
                    payload: ActivityFactory.instantiate({
                        ...rest,
                        id_materiales_mantenimiento: this.$route.params.id,
                    }),
                    id_material: this.$route.params.id_material,
                    id_mantenimiento: this.$route.params.id,
                })
            }
            this.cleanFields()
            this.$refs.modalCreateActivity.toggle()
        },

        cleanFields(){
            this.activity = ActivityFactory.instantiateEmpty()
        }
    },
    async mounted(){
        await this.obtenerTiposCalendarios()
    }
}
</script>
<style lang="scss" scoped>
.create-activity{
    .delete-file{
        width: 23px;
        height: 24px;
        background-color: #707070;
    }
}
</style>
